import './App.scss';
import logoAzul from './assets/imgs/animacion_logo-azul.gif'
import line from './assets/imgs/line.png'
import elipse from './assets/imgs/elipse.png'
import Mitikah from './assets/imgs/Mitikah.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logoAzul} className="logo" alt="Inefable" />
      </header>

      <div className='phrase'>
        "La creatividad es la fuerza <br className='mb'/>
        de los ejércitos revolucionarios."
        <br />
        <img src={line} className='line' alt='line' />
      </div>

      <div className='construction'>
        Sitio en construcci<span className='orange'>ó</span>n. <br/>
        <img src={elipse} className='elipse' alt='elipse' />
      </div>

      <div className='footer'>
        <div className='together'>
          <div className='img'>
            <img src={Mitikah} alt='Mitikah' className='Mitikah'/>
          </div>
          <div className='address'>
          <a href='https://maps.app.goo.gl/GeVQMuJdzjP2TpSu8' target="_blank" rel="noreferrer">  Mitikah, Av. Río Churubusco 601, Xoco,<br />
            Coyoacán, 03330 Ciudad de México, CDMX </a>
          </div>
        </div>
        <div className='contact'>
          <a href='mailto:contacto@inefable.mx'>contacto@inefable.mx</a><br />
          <a href="tel:5517004482">55 1700 4482</a>
        </div>
      </div>
    </div>
  );
}

export default App;
